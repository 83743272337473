import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productosCarrito'
})
export class ProductosCarritoPipe implements PipeTransform {

  transform(items: any[], filter: any): any {
    if (!items || !filter) {
        return items;
    }
    console.log(filter);
    console.log('items', items);
    var itemsFilter:any[]= [];
    itemsFilter = items.filter(item => item.cantidadCarrito > 0);
    console.log('itemsFilter',itemsFilter);
    return itemsFilter;
}

}
