import { Component, HostListener, OnInit, ɵConsole } from '@angular/core';
import { GoogleSheetService } from 'src/app/services/google-sheet.service';
import { ToastrService } from 'ngx-toastr';
import {
  formatJSONGoogleSheets,
  deleteDuplicates,
  titleCase,
  formatJSONCabecera,
} from '../../commons/app-utils.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as $ from 'jquery';
import * as moment from 'moment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { CartService } from 'src/app/services/cart.service';
import { KEY_AGROBEAT_PRODUCTOS, Spreadsheet } from 'src/app/config/constants';

@Component({
  selector: 'app-one-page-checkout',
  templateUrl: './one-page-checkout.component.html',
  styleUrls: ['./one-page-checkout.component.css'],
})
export class OnePageCheckoutComponent implements OnInit {
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  products: any = [];
  filteredData: any = [];
  categories: any = [];
  openFilter: boolean = false;
  idCategoryFilter: number = -1;
  titleFilter: string = 'Seleccionar categoría';
  categoriaNombre: string = '';
  productoObervacion: any = {};
  filterargs = { categoria: '' };
  totalItems: any = 0;
  bottomCart: boolean = false;
  checkoutWeb: boolean = false;
  seeCart: boolean = false;
  seeCheckout: boolean = false;
  seeObservacion: boolean = false;
  seeObservacionWeb: boolean = false;
  seeImagenFull: boolean = false;
  totalCarrito: number = 0;
  filtercart = { cantidadCarrito: 0 };
  frmRegister: FormGroup;
  frmObservacion: FormGroup;
  submitted: boolean = false;
  desktop: boolean = false;
  totalItemsCart: number = 0;
  pedidoMensaje: any = '';
  envioPrecio: any = 10000;
  envioTelefono: any = '';
  cleanButton: boolean = false;
  ordenCarrito: number = 0;
  modificarDatosUser: boolean = false;
  compraMinima: number = 50000;
  colorDestacado: string = 'transaparent';
  textoDestacado: string = 'DESTACADO';
  productImageFull: any = {};
  currency: string = 'Gs.';
  logoEmpresa: string = '';
  tiendaNombre: String = '';
  spreadsheetID: any = null;
  colorPrimario: any = '';
  totalPriceCart: any = 0;
  isLoading: boolean = true;
  currentYear: number = new Date().getFullYear();

  constructor(
    private cartService: CartService,
    private sidenavService: SidenavService,
    private router: Router,
    private titleService: Title,
    private meta: Meta,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private googleSheetService: GoogleSheetService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.totalItemsCart = this.cartService.getTotalItems();
    this.cartService.cartItems$.subscribe((items) => {
      this.totalItemsCart = this.cartService.getTotalItems();
      this.totalPriceCart = this.cartService.getTotalPrice();
    });

    this.spreadsheetID = Spreadsheet;
    this.listarCategorias();
    // this.listarCabecera();

    this.products.total = 0;
    this.frmRegister = this.fb.group({
      regNombre: [null, [Validators.required]],
      regApellido: [null, [Validators.required]],
      regRazonSocial: [null],
      regCedula: [null, [Validators.required]],
      regDireccion: [null, [Validators.required]],
      regCorreo: [null, [Validators.required]],
      regObservacionPedido: [null],
    });
    if (localStorage.getItem('userFF') != undefined) {
      this.modificarDatosUser = true;
      this.autocompleteUserFF();
    }
    this.frmObservacion = this.fb.group({
      observacion: '',
    });
  }
  autocompleteUserFF() {
    var userFF = JSON.parse(localStorage.getItem('userFF'));
    this.frmRegister.patchValue({
      regNombre: userFF.regNombre,
      regApellido: userFF.regApellido,
      regRazonSocial: userFF.regRazonSocial,
      regCedula: userFF.regCedula,
      regDireccion: userFF.regDireccion,
      regCorreo: userFF.regCorreo,
      regObservacionPedido: userFF.regObservacionPedido,
    });
  }
  get f() {
    return this.frmRegister.controls;
  }

  listarCabecera() {
    this.googleSheetService.getCabecera(this.spreadsheetID).subscribe(
      (data: any) => {
        var cabecera: any = formatJSONCabecera(data);
        if (cabecera.nro_whatsapp != '') {
          this.envioTelefono = cabecera.nro_whatsapp;
        }
        if (cabecera.costo_del_delivery != '') {
          this.envioPrecio = cabecera.costo_del_delivery;
        }
        if (cabecera.minimo_de_compra != '') {
          this.compraMinima = cabecera.minimo_de_compra;
        }
        if (cabecera.color_producto_destacado != '') {
          this.colorDestacado = cabecera.color_producto_destacado;
        }
        if (cabecera.moneda != '') {
          this.currency = cabecera.moneda;
        }
        if (cabecera.logo_empresa != '') {
          this.logoEmpresa = cabecera.logo_empresa;
        }
        if (cabecera.texto_destacado != '') {
          this.textoDestacado = cabecera.texto_destacado;
        }
        if (cabecera.color_primario != '') {
          this.colorPrimario = cabecera.color_primario;
          $('#dropdownMenuButtonCategory').attr(
            'style',
            'background-color: ' + this.colorPrimario + ' !important'
          );
        }

        console.log(cabecera);
      },
      (err: any) => {
        this.toastr.error(
          'Ocurrió un error inesperado al obtener la cabecera.',
          ''
        );
      }
    );
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  metaData(name: string, desc: string, image: string) {
    this.meta.updateTag({ property: 'og:description', content: desc });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:title', content: name });
  }

  listarCategorias() {
    this.googleSheetService.getCategories(this.spreadsheetID).subscribe(
      (data: any) => {
        if (data) {
          if (data.values) {
            for (let index = 1; index < data.values.length; index++) {
              const element = data.values[index];
              this.categories.push(element[5]);
            }
            this.categories = deleteDuplicates(this.categories);
          }
        }
        this.listarProductos();
      },
      (err: any) => {
        this.toastr.error(
          'Ocurrió un error inesperado al listar los productos.',
          ''
        );
      }
    );
  }

  listarProductos() {
    this.isLoading = true;
    this.googleSheetService.getProducts(this.spreadsheetID).subscribe(
      (data: any) => {
        this.products = formatJSONGoogleSheets(data);
        localStorage.setItem(
          KEY_AGROBEAT_PRODUCTOS,
          JSON.stringify(this.products)
        );
        console.log(this.products);
        this.filteredData = this.products;
        setTimeout(() => {
          this.totalItems = $('#listPrincipal .product').length;
          this.isLoading = false;
        }, 200);
      },
      (err: any) => {
        this.toastr.error(
          'An unexpected error occurred while listing the products.',
          ''
        );
        this.isLoading = false;
      }
    );
  }

  seleccionarCategoria(i, nombreCategoria) {
    if (this.idCategoryFilter != i) {
      this.idCategoryFilter = i;
      this.openFilter = false;
      this.titleFilter = 'Fitrado por: ';
      this.categoriaNombre = nombreCategoria;
      this.filterargs = { categoria: nombreCategoria };
    } else {
      this.idCategoryFilter = -1;
      this.openFilter = false;
      this.titleFilter = 'Seleccionar categoría';
      this.categoriaNombre = '';
      this.filterargs = { categoria: '' };
      this.openDropdown();
    }
    setTimeout(() => {
      this.totalItems = $('#listPrincipal .product').length;
    }, 200);
  }

  openDropdown() {
    this.openFilter = !this.openFilter;
  }

  calcularTotal(salto, precio) {
    this.totalCarrito += Number(salto) * Number(precio);
    if (this.totalCarrito == 0) {
      this.bottomCart = false;
    }
    localStorage.setItem('totalCarrito', String(this.totalCarrito));
  }

  precioTotalProducto(cant, precio) {
    return Number(cant) * Number(precio);
  }

  precioFinal(precio) {
    return Number(precio) + Number(this.envioPrecio);
  }

  showCart() {
    this.sidenavService.toggle();
  }

  showCheckout() {
    if (this.totalCarrito >= this.compraMinima) {
      this.seeCart = false;
      this.seeCheckout = true;
    } else {
      this.toastr.warning(
        'La compra mínima es de ' + this.transform(this.compraMinima)
      );
    }
  }

  generarPedido() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.frmRegister.invalid) {
      document.getElementById('formulario').scrollIntoView();
      return;
    }
  }

  modificarCarrito() {
    this.seeCart = true;
    this.seeCheckout = false;
  }

  filterProducts(val) {
    this.cleanButton = false;
    this.limpiarFiltroCategoria();
    this.filteredData = this.products.filter(
      (d) => d.nombre.toLowerCase().indexOf(val.toLowerCase()) >= 0
    );
    setTimeout(() => {
      this.totalItems = $('#listPrincipal .product').length;
    }, 200);
    if (val != '') {
      this.cleanButton = true;
    }
  }
  cleanSearch() {
    this.cleanButton = false;
    this.filteredData = this.products.filter(
      (d) => d.nombre.toLowerCase().indexOf('') >= 0
    );
    $('#inputSearch').val('');
  }
  limpiarFiltroCategoria() {
    if (this.filterargs.categoria != '') {
      window.scroll(0, 0);
      //Al buscar productos, el filtro por categoria se elimina
      this.idCategoryFilter = -1;
      this.titleFilter = 'Seleccionar categoría';
      this.categoriaNombre = '';
      this.filterargs = { categoria: '' };
      //-----//
    }
  }

  transform(num: any) {
    var separador = '.';
    num += '';
    var numeroDecimal = num.split('.')[0];
    var decimal = '';
    if (num.split('.')[1]) {
      decimal = ',' + num.split('.')[1].substring(0, 2);
    }
    var splitStr = numeroDecimal.split('.');
    var splitLeft = splitStr[0];

    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, '$1' + separador + '$2');
    }
    return splitLeft + decimal;
  }

  agregarObservacion(row, event) {
    event.preventDefault();
    this.productoObervacion = row;
    if ($(window).width() <= 991) {
      this.seeObservacion = true;
      this.seeCart = false;
      $('.modal-backdrop').remove();
    } else {
      this.seeObservacionWeb = true;
    }
    this.frmObservacion.controls['observacion'].setValue('');
    if (this.productoObervacion.observacion != '') {
      this.frmObservacion.controls['observacion'].setValue(
        this.productoObervacion.observacion
      );
    }
  }
  verImagenFull(row, event) {
    this.seeImagenFull = true;
    this.productImageFull = row;
    event.preventDefault();
  }
  setearObservacion() {
    if (this.productoObervacion) {
      if (this.frmObservacion.get('observacion').value != '') {
        var observacion = this.frmObservacion.get('observacion').value;
        this.productoObervacion['observacion'] = observacion;
        this.productoObervacion = {};
        this.frmObservacion.controls['observacion'].setValue('');
        localStorage.setItem('itemsFF', JSON.stringify([...this.products]));
        var today = moment().format('YYYY-MM-DD').split('-');
        localStorage.setItem('ultimaconexionFF', String(today));
      } else {
        this.toastr.error('La observación no puede quedar en blanco.');
      }
    }
  }
  limpiarDatosUserFF() {
    this.frmRegister.patchValue({
      regNombre: null,
      regApellido: null,
      regRazonSocial: null,
      regCedula: null,
      regDireccion: null,
      regCorreo: null,
      regObservacionPedido: null,
    });
    this.modificarDatosUser = false;
  }

  finalizarPedidoWeb() {
    if (this.totalCarrito >= this.compraMinima) {
      window.scrollTo(0, 0);
      this.checkoutWeb = !this.checkoutWeb;
    } else {
      this.toastr.warning(
        'La compra mínima es de ' + this.transform(this.compraMinima)
      );
    }
  }

  modificarCarritoWeb() {
    this.checkoutWeb = !this.checkoutWeb;
  }

  enviarPedido() {
    this.submitted = true;
    if (!this.frmRegister.valid) {
      document.getElementById('formulario').scrollIntoView();
      return;
    }

    //Numero de pedido
    var timestamp = (+new Date()).toString();
    var cedulaRuc = '';
    timestamp = timestamp.substr(timestamp.length - 3);
    if (this.frmRegister.controls.regCedula.value.indexOf('-') > 0) {
      var cedula = this.frmRegister.controls.regCedula.value.split('-')[0];
      cedulaRuc = cedula.substr(cedula.length - 3);
    } else {
      var cedula = this.frmRegister.controls.regCedula.value;
      cedulaRuc = cedula.substr(cedula.length - 3);
    }

    //Generacion de mensaje
    var totalCompra = 0;
    var stringNumeroPedido =
      '📝 *Nuevo Pedido* ' +
      ('( %23WA-' + cedulaRuc + timestamp).toString() +
      ' )%0A%0A';
    this.pedidoMensaje = stringNumeroPedido;
    this.products.forEach((element) => {
      if (element.cantidadCarrito > 0) {
        totalCompra += element.cantidadCarrito * element.precio;
        this.pedidoMensaje +=
          '◽ ' +
          'SKU: ' +
          element.sku +
          ' - ' +
          titleCase(element.nombre) +
          ' ( ' +
          titleCase(element.unidad) +
          ' )%0A';
        this.pedidoMensaje +=
          element.cantidadCarrito +
          ' x Gs ' +
          this.transform(element.precio) +
          '%0A';
        this.pedidoMensaje +=
          'Total: Gs ' +
          this.transform(+element.cantidadCarrito * +element.precio);
        if (element.observacion && element.observacion != '') {
          this.pedidoMensaje += '%0AObs: ' + element.observacion + '%0A%0A';
        } else {
          this.pedidoMensaje += '%0A%0A';
        }
      }
    });
    this.pedidoMensaje +=
      '*Total*: ' +
      this.currency +
      ' ' +
      this.transform(+totalCompra + +this.envioPrecio) +
      '%0A%0A' +
      '---------------%0A%0A' +
      '👤 *Datos del Cliente:*%0A%0A' +
      '*Nombre y Apellido*%0A' +
      this.frmRegister.controls.regNombre.value +
      ' ' +
      this.frmRegister.controls.regApellido.value +
      '%0A%0A';
    if (
      this.frmRegister.controls.regRazonSocial.value != '' &&
      this.frmRegister.controls.regRazonSocial.value != null
    ) {
      this.pedidoMensaje +=
        '*Razón social*%0A' +
        this.frmRegister.controls.regRazonSocial.value +
        '%0A%0A';
    }
    this.pedidoMensaje +=
      '*Cédula o RUC*%0A' +
      this.frmRegister.controls.regCedula.value +
      '%0A%0A' +
      '*Dirección*%0A' +
      this.frmRegister.controls.regDireccion.value +
      '%0A%0A' +
      '*Correo electrónico*%0A' +
      this.frmRegister.controls.regCorreo.value +
      '%0A%0A';
    if (
      this.frmRegister.controls.regObservacionPedido.value != '' &&
      this.frmRegister.controls.regObservacionPedido.value != null
    ) {
      this.pedidoMensaje +=
        '*Observación para entrega de pedido*%0A' +
        this.frmRegister.controls.regObservacionPedido.value +
        '%0A%0A';
    }
    localStorage.setItem('userFF', JSON.stringify(this.frmRegister.value));
    localStorage.removeItem('totalCarrito');
    localStorage.removeItem('totalItems');
    localStorage.removeItem('itemsFF');
    window.open(
      <any>(
        ('https://api.whatsapp.com/send?phone=' +
          this.envioTelefono +
          '&text=' +
          this.pedidoMensaje)
      ),
      '_blank'
    );
  }

  verProducto(sku: any) {
    this.router.navigate(['/detalle', sku]);
  }

  openSidenav() {
    this.sidenavService.open();
  }

  closeSidenav() {
    this.sidenavService.close();
  }

  toggleSidenav() {
    this.sidenavService.toggle();
  }

  onCategorySelected(category: string) {
    this.filterargs = { categoria: category || '' };
    setTimeout(() => {
      this.totalItems = $('#listPrincipal .product').length;
    }, 200);
  }

  getLowestPrice(prices: any): number {
    if (!prices || typeof prices !== 'object') { 
      return 0;
    }
    const priceValues = Object.values(prices).filter(price => typeof price === 'number');
    return priceValues.length > 0 ? Math.min(...priceValues as number[]) : 0;
  }
}