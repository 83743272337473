import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separadormiles'
})
export class SeparadormilesPipe implements PipeTransform {
  public transform(num: any) {
    var separador = '.';
    num += '';
    var numeroDecimal = num.split(".")[0];
    var decimal = "";
    if (num.split(".")[1]) {
        decimal = "," + num.split(".")[1].substring(0, 2);
    }
    var splitStr = numeroDecimal.split('.');
    var splitLeft = splitStr[0];

    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + separador + '$2');
    }
    return splitLeft + decimal;
}

}
