<app-header 
  [totalItemsCart]="totalItemsCart"
  (filterEvent)="filterProducts($event)"
  [filter]="true"
  (toggleSidenavEvent)="toggleSidenav()">
</app-header>

<div class="container">
    <div>
        <div class="row subheader">
            <div class="col-md-12 mb-3 mt-3">
                <app-categorias [data]="categories" (categorySelected)="onCategorySelected($event)"></app-categorias>
            </div>
            <div class="col-md-12">
                <p style="text-align: right;" class="mt-1 mb-0">Total items: <span class="font-weight-bold">{{totalItems}}</span> </p>
            </div>
        </div>
        <!-- PRODUCTOS -->
 
        <div class="row relative">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="products products-table" id="listPrincipal">
                    <div class="product box rounded-lg" (click)="verProducto(product.sku)" *ngFor="let product of filteredData | filterProducts:filterargs; let i = index ">
                        <div class="product-img-container">
                            <div class="product-img rounded-lg" style="background-image:url({{product.url}});" [hidden]="product.url==''">
                            </div>
                            <div class="product-img" style="background-image:url(../../../assets/images/image-placeholder.jpg);" [hidden]="product.url!=''">
                            </div>
                            <div class="lowest-price" *ngIf="getLowestPrice(product.precios) > 0">
                                <span>from</span> ${{ getLowestPrice(product.precios) }}
                            </div>
                        </div>
                        <div class="product-content">
                            <h3>
                                {{product.nombre}}
                                <small>{{product.unidad  | titlecase}}</small>
                            </h3>
                            <span class="presentaciones">{{product.presentaciones}}</span>
                            <p>
                                {{product.descripcion}}
                            </p>
                        </div>
                    </div>
                    <!-- Mensaje modificado para "No products found" -->
                    <div *ngIf="!isLoading && (filteredData | filterProducts:filterargs).length === 0" class="no-products-message">
                        <p>No products found.</p>
                    </div>

                    <!-- Opcional: Agregar un indicador de carga -->
                    <div *ngIf="isLoading" class="no-products-message">
                        <p>Loading products...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<!-- Ir al carrito y total -->

<div class="bottom-div cssanimation sequence bounceInBottom d-md-none" *ngIf="totalItemsCart>0">
    <div class="row h-100">
        <div class="col-lg-6 col-5">
            <div class="d-block text-total h-100 pt-3">
                <p class="mb-0">Total</p>
                <h6 class="font-weight-bold">$ {{totalPriceCart | separadormiles }}</h6>
            </div>
        </div>
        <div class="col-lg-6 col-7">
            <div class="container pt-3">
                <button (click)="showCart()" class="btn btn-lg btn-light font-weight-bold px-3 float-right"><mat-icon svgIcon="cart"></mat-icon>&nbsp;&nbsp;View cart</button>
            </div>
        </div>
    </div>

</div>

<!-- Footer -->
<footer class="footer mt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
        <div class="footer-logo">
          <img src="assets/logo/logo-malek.svg" alt="Malek Distribution Logo" height="60">
        </div>
        <p class="footer-subheading">Vape & Smoke Shop Supplier</p>
      </div>
      <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
        <h6 class="footer-section-title">Address</h6>
        <p class="footer-text">779 Lakeside Dr, Mobile, AL, 36693</p>
      </div>
      <div class="col-md-6 col-lg-4">
        <h6 class="footer-section-title">Contact</h6>
        <p class="footer-text">
          <strong>Email:</strong> <a href="mailto:malekorder@yahoo.com">malekorder@yahoo.com</a><br>
          <strong>Phone:</strong> <a href="tel:+12516076542">+1 251 607-6542</a><br>
          <strong>Cell / WhatsApp:</strong> <a href="https://wa.me/12519016034">+1 251 901-6034</a>
        </p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <p class="footer-copyright">&copy; {{currentYear}} Malek Distribution. All rights reserved.</p>
      </div>
    </div>
  </div>
</footer>
