import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent{
  @Input() cartItems: any[];
  @Input() totalPrice: number;
  constructor() { }

  ngOnInit(): void {
  }

}
