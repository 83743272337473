import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Input() totalItemsCart: number = 0;
  @Input() filter: boolean = false;
  @Output() filterEvent = new EventEmitter<string>();
  @Output() toggleSidenavEvent = new EventEmitter<void>();
  cleanButton = false;


  constructor(private router:Router) {
    if(this.filterEvent){

    }
   }


  onFilter(searchText: string) {
    this.cleanButton = !!searchText;
    this.filterEvent.emit(searchText);
  }

  onCleanSearch() {
    this.cleanButton = false;
    this.filterEvent.emit('');
  }

  onToggleSidenav() {
    this.toggleSidenavEvent.emit();
  }
  irinicio(){
    this.router.navigate(["/"])
  }
}
