import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { GoogleSheetsDbService } from 'ng-google-sheets-db';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OnePageCheckoutComponent } from './components/one-page-checkout/one-page-checkout.component';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SeparadormilesPipe } from './pipe/separadormiles.pipe';
import { FilterProductsPipe } from './pipe/filter-products.pipe';
import { ProductosCarritoPipe } from './pipe/productos-carrito.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { OrderCartPipe } from './pipe/order-cart.pipe';
import { MaterialModule } from './modulos/material/material.module';
import { ProductoDetalleComponent } from './components/producto-detalle/producto-detalle.component';
import { CarritoComponent } from './components/carrito/carrito.component';
import { ProductoComponent } from './components/commons/producto/producto.component';
import { HeaderComponent } from './components/header/header.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { CheckoutComponent } from './components/checkout/checkout.component';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CategoriasComponent } from './components/commons/categorias/categorias.component';
import { OrderSummaryComponent } from './components/commons/order-summary/order-summary.component';
import { QuantitySelectorComponent } from './components/commons/quantity-selector/quantity-selector.component';
import { PhoneMaskDirective } from './directivas/phone-mask.directive';

@NgModule({
  declarations: [
    AppComponent,
    OnePageCheckoutComponent,
    SeparadormilesPipe,
    FilterProductsPipe,
    ProductosCarritoPipe,
    OrderCartPipe,
    ProductoDetalleComponent,
    CarritoComponent,
    ProductoComponent,
    HeaderComponent,
    EmptyStateComponent,
    CheckoutComponent,
    CategoriasComponent,
    OrderSummaryComponent,
    QuantitySelectorComponent,
    PhoneMaskDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    MaterialModule,
  ],
  providers: [ GoogleSheetsDbService ],
  bootstrap: [AppComponent]
})
export class AppModule { 

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'cross',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cross.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'cart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cart.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'check',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/check.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chevron-left.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chevron-down.svg')
    );
    
  }


}
