<app-header 
  [totalItemsCart]="totalItemsCart"
  [filter]="false"
  (toggleSidenavEvent)="toggleSidenav()">
  
</app-header>

<div class="container">
  <div class="detalle-view mt-4">
    <div class="product-image">
      <div class="img" style="background-image: url({{ producto.url }});"></div>
    </div>
    <div class="product-item">
      <h3 class="product-name">{{ producto.nombre }}</h3>
      <small class="product-descripcion" [class.expanded]="descripcionExpandida">
        {{ producto.descripcion }}
      </small>
      <a *ngIf="!descripcionExpandida" (click)="descripcionExpandida = true" class="ver-mas">Read More</a>
      <a *ngIf="descripcionExpandida" (click)="descripcionExpandida = false" class="ver-mas">Read Less</a>
      
      <h4 class="mt-3">SIZES</h4>
      <div class="presentaciones">
        <div
          class="presentacion"
          [ngClass]="{ selected: presentacionSeleccionada == presentacion }"
          *ngFor="let presentacion of producto.presentaciones"
          (click)="selectPresentacion(presentacion)"
        >
          {{ presentacion }}
        </div>
      </div>
      <h4 class="mt-3">PRICE</h4>
      <p class=""> <span *ngIf="presentacionSeleccionada==null" class="product-disable">Select a size</span> <span *ngIf="presentacionSeleccionada!=null" class="product-precio">$&nbsp;{{producto.precios[presentacionSeleccionada] | separadormiles}}</span> </p>
      <button
        class="btn btn-primary  mt-3"
        [disabled]="presentacionSeleccionada == null"
        (click)="addProductToCart(producto)"
      >
        Add to cart
      </button>
    </div>
  </div>
</div>
