import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit {
  cartItems: any = [];
  totalPrice:any=0;
  constructor(
    private sidenavService: SidenavService,
    private cartService: CartService,
    private router:Router
  ) {}

 
  ngOnInit(): void {
    this.cartItems = this.cartService.loadCart();
    this.totalPrice = this.cartService.getTotalPrice();
    this.cartService.cartItems$.subscribe((items) => {
      this.cartItems = items;
      this.totalPrice = this.cartService.getTotalPrice();
    });
  }

  closeSidenav() {
    this.sidenavService.close();
  }

  checkout(){
    this.sidenavService.toggle();
    this.router.navigate(["/checkout"])
  }
}
