/**
    keys: Nombres de las columnas en el documento Google Sheets
    values: Valores por fila en el documento Google Sheets
    Obs: Los nombres de columnas que llevan espacio se reemplazadan por _ (guión bajo).
**/
function transformarProducto(producto: any): any {
  const presentacionesArray = producto.sizes.split(',');
  const preciosArray = producto.prices.split(',');

  const precios: { [presentacion: string]: number } = {};

  presentacionesArray.forEach((presentacion, index) => {
    if (presentacion && preciosArray[index]) {
      precios[presentacion.trim()] = Number(preciosArray[index].trim());
    }
  });

  var presentaciones = [];
  presentaciones = producto.sizes 
  ? producto.sizes.split(',')
      .map(p => p.trim())
      .filter(p => p !== "") 
  : [];

  return {
    sku: producto.sku,
    nombre: producto.name,
    descripcion: producto.description,
    categoria: producto.category,
    url: producto.url,
    observacion: producto.observacion,
    cantidadCarrito: producto.cantidadCarrito,
    presentaciones: presentaciones,
    precios: precios,
  };
}

export function formatJSONGoogleSheets(array) {
  if (array) {
    if (array.values) {
      var keys = array.values[0];
      var values = array.values;
      var productos = [];
      for (let index = 1; index < values.length; index++) {
        const element = values[index];
        var objecto = {};
        for (let index2 = 0; index2 < element.length; index2++) {
          const cells = element[index2];
          if (keys[index2].indexOf(' ') == -1) {
            objecto[(keys[index2] + '').toLocaleLowerCase()] = cells;
          } else {
            objecto[keys[index2].replaceAll(' ', '_').toLocaleLowerCase()] =
              cells;
          }
        }
        objecto['cantidadCarrito'] = 0;
        objecto['observacion'] = '';

        var productoFinal = transformarProducto(objecto);
        productos.push(productoFinal);
      }
    }
  }
  return productos;
}

export function formatJSONCabecera(array) {
  if (array.valueRanges) {
    if (array.valueRanges.length > 0) {
      var cabeceraData = {};
      console.log(array.valueRanges);
      for (let index = 0; index < array.valueRanges.length; index++) {
        var columna = array.valueRanges[index].values;
        const header = columna[0];
        const data = columna[1];
        for (let index = 0; index < header.length; index++) {
          const nameHeader = header[index];
          cabeceraData[nameHeader.replaceAll(' ', '_').toLocaleLowerCase()] =
            data[index];
        }
      }
    }
  }
  return cabeceraData;
}

export function deleteDuplicates(arr) {
  if (arr) {
    var newArray = arr.filter((elem, i, arr) => {
      if (arr.indexOf(elem) === i) {
        return elem;
      }
    });
  }
  return newArray;
}

export function titleCase(str) {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}
