<div class="order-summary">
    <div
      class="d-flex align-items-center mb-4 position-relative producto-detail"
      *ngFor="let producto of cartItems"
    >
      <div class="container-img">
        <span class="cantidad-producto">
          {{ producto.cantidad }}
        </span>
        <img
          src="{{ producto.url }}"
          alt="Product Image"
          class="img-fluid me-3"
          style="max-width: 60px"
        />
      </div>
  
      <div class="flex-grow-1">
        <p class="mb-1">{{ producto.nombre }}</p>
        <small class="text-muted">{{ producto.presentacion }}</small>
      </div>
      <div class="fw-bold">
        $&nbsp;{{ producto.precio | separadormiles }}
      </div>
    </div>
  
  </div>