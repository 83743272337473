import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartItem, CartService } from 'src/app/services/cart.service';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  KEY_AGROBEAT_CART,
  KEY_AGROBEAT_PEDIDO_PENDIENTE,
  KEY_AGROBEAT_PRODUCTOS,
  KEY_AGROBEAT_USER,
} from 'src/app/config/constants';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { CheckoutService } from 'src/app/services/checkout/checkout.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  cartItems: CartItem[] = [];
  totalPrice: any = 0;
  isOrderSummaryVisible = false;
  form: FormGroup;
  isLoading = false;
  pedido_id: any = null;
  submit: any = false;
  validado: any = false;
  estado_pedido: any = null;
  constructor(
    private cartService: CartService,
    private router: Router,
    private location: Location,
    private notificacionesService: NotificacionesService,
    private checkoutService: CheckoutService,
    private http: HttpClient
  ) {
    this.form = new FormGroup({
      contact: new FormControl(null, [
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14)
      ]),
      subscribe_news: new FormControl(false),
      country_region: new FormControl('United States', [Validators.required]),
      first_name: new FormControl(null, [Validators.required]),
      last_name: new FormControl(null),
      address: new FormControl(null, [Validators.required]),
      apartment_suite: new FormControl(null),
      city: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      zip_code: new FormControl(null, [
        Validators.required
      ]),
      // save_information: new FormControl(false),
    });
    this.pedido_id = localStorage.getItem(KEY_AGROBEAT_PEDIDO_PENDIENTE);
  }

  toggleOrderSummary() {
    this.isOrderSummaryVisible = !this.isOrderSummaryVisible;
  }
  ngOnInit(): void {
    this.validar_pedido();
    this.cartItems = this.cartService.loadCart();
    this.totalPrice = this.cartService.getTotalPrice();
    this.cartService.cartItems$.subscribe((items) => {
      this.cartItems = items;
      this.totalPrice = this.cartService.getTotalPrice();
    });
  }

  atras() {
    this.router.navigate(['/']);
  }

  // onSubmit() {
  //   console.log(this.form);
  //   this.submit = true;
  //   if (this.form.valid) {
  //     const productos = this.cartItems.map((producto) => ({
  //       sku: producto.sku,
  //       nombre: producto.nombre,
  //       cantidad: producto.cantidad,
  //       presentacion: producto.presentacion,
  //     }));

  //     var data = {
  //       pedido_id: this.pedido_id,
  //       ruc: this.form.get('ruc').value,
  //       productos: productos,
  //     };
  //     this.isLoading = true;
  //     this.checkoutService.enviar_pedido(data).subscribe({
  //       next: (response) => {
  //         this.notificacionesService.success.next(
  //           '¡Hecho! Tu pedido está enviado.'
  //         );
  //         this.router.navigate(['/']);
  //         this.clearStorage();
  //         this.cartService.clearCart();
  //       },
  //       error: (response) => {
  //         this.notificacionesService.error.next(response.error.mensaje);
  //       },
  //       complete: () => ((this.isLoading = false), (this.submit = false)),
  //     });
  //   }
  // }

  onSubmit() {
    this.submit = true;
    if (this.form.valid) {
      this.isLoading = true; // Activate loading indicator
      var order = this.form.value;
      const productos = this.cartItems.map((producto) => ({
        sku: producto.sku,
        nombre: producto.nombre,
        cantidad: producto.cantidad,
        presentacion: producto.presentacion,
        precio: producto.precio,
      }));
      order["products"] = productos;
      this.checkoutService.enviar_pedido(order)
        .subscribe({
          next: (response) => {
            this.notificacionesService.success.next(
              'Done! Your order has been sent.'
            );
            this.router.navigate(['/']);
            this.clearStorage();
            this.cartService.clearCart();
          },
          error: (response) => {
            this.notificacionesService.error.next(response.error.mensaje);
          },
          complete: () => {
            this.isLoading = false; // Deactivate loading indicator
            this.submit = false;
          },
        });
    }
  }


  private clearStorage(): void {
    [
      KEY_AGROBEAT_PEDIDO_PENDIENTE,
      KEY_AGROBEAT_USER,
      KEY_AGROBEAT_PRODUCTOS,
    ].forEach((key) => localStorage.removeItem(key));
  }

  validar_pedido() {
    if (this.pedido_id == null) {
      const telephoneControl = this.form.get('telephone');
      telephoneControl?.setValidators([Validators.required]);
      telephoneControl?.updateValueAndValidity();

      return;
    }
    this.checkoutService.validar_pedido(this.pedido_id).subscribe({
      next: (response) => {
        this.validado = true;
        this.estado_pedido = response.estado;

        const telephoneControl = this.form.get('telephone');

        if (
          ['encamino', 'completado', 'cancelado'].includes(this.estado_pedido)
        ) {
          localStorage.removeItem(KEY_AGROBEAT_PEDIDO_PENDIENTE);
          localStorage.removeItem(KEY_AGROBEAT_USER);
          this.pedido_id = null;

          telephoneControl?.setValidators([Validators.required]);
        } else {
          telephoneControl?.setValidators(null);

          var data_user = JSON.parse(
            localStorage.getItem(KEY_AGROBEAT_USER) || '{}'
          );

          if (data_user != null) {
            this.form.patchValue({
              razon_social: data_user['razon_social'],
              ruc: data_user['ruc'],
              latitud: data_user['latitud'],
              longitud: data_user['longitud'],
            });
          }
        }

        // Recalcular la validez del campo después de cambiar los validadores
        telephoneControl?.updateValueAndValidity();
      },
      error: (response) => { },
      complete: () => {
        this.isLoading = false;
        this.submit = false;
      },
    });
  }
}
