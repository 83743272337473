<div class="container">
  <div class="row" style="min-height: 100vh;">
    <!-- Sección Izquierda: Formulario de Checkout -->
    <div class="col-12 col-lg-7 border-md-right border-sm-none p-3">
      <div class="p-md-4 p-2 bg-white">
        <p class="atras" (click)="atras()">
          <mat-icon svgIcon="chevron-left"></mat-icon> Back
        </p>
        <div class="mb-4 logo-container" routerLink="/">
          <div class="img"></div>
        </div>
        <div
          class="order-summary-container d-flex d-md-none"
          (click)="toggleOrderSummary()"
        >
          <p class="m-0">
            Show order summary
            <mat-icon svgIcon="chevron-down"></mat-icon>
          </p>
          <span class="total-price-order">
            $&nbsp;{{ totalPrice | separadormiles }}</span
          >
        </div>
        <div *ngIf="isOrderSummaryVisible" class="order-summary-mobile mb-4">
          <app-order-summary
            [cartItems]="cartItems"
            [totalPrice]="totalPrice"
          ></app-order-summary>
          <div class="border-top pt-3">
            <div class="d-flex justify-content-between mb-2">
              <span>Subtotal</span>
              <span>$&nbsp;{{ totalPrice | separadormiles }}</span>
            </div>
            <!-- <div class="d-flex justify-content-between mb-2">
              <span>Delivery</span>
              <span>Sin costo</span>
            </div> -->
            <div class="d-flex justify-content-between">
              <strong>Total</strong>
              <strong>$&nbsp;{{ totalPrice | separadormiles }}</strong>
            </div>
          </div>
        </div>
        <form class="content" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div>
            <h4 class="mb-3" style="font-weight: 700">Contact</h4>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="first_name">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="First name"
                  formControlName="first_name"
                />
                <div *ngIf="form.get('first_name').invalid && submit" class="text-danger">
                  <small *ngIf="form.get('first_name').hasError('required')">Your name is required.</small>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="last_name">Last Name (Optional)</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Last name"
                  formControlName="last_name"
                />
              </div>
              <div class="col-md-12 mb-3">
                <label for="contact">WhatsApp Number</label>
                <div style="position: relative;">
                  <span class="prefix">+1</span>
                  <input
                    type="text"
                    class="form-control wprefix"
                    placeholder="(000) 000-0000"
                    formControlName="contact"
                    maxlength="14"
                    appPhoneMask
                  />
                </div>
                
                <div *ngIf="form.get('contact').invalid && submit" class="text-danger">
                  <small *ngIf="form.get('contact').hasError('required')">Contact is required.</small>
                  <small *ngIf="form.get('contact')?.errors?.minlength">Invalid US phone number. Must be 10 digits.</small>
                </div>
              </div>
            </div>
          </div>
        
          <h4 class="mb-3 mt-3" style="font-weight: 700">Shipping address</h4>
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="address">Address</label>
              <input
                type="text"
                class="form-control"
                placeholder="Address"
                formControlName="address"
              />
              <div *ngIf="form.get('address').invalid && submit" class="text-danger">
                <small *ngIf="form.get('address').hasError('required')">Address is required.</small>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <label for="apartment_suite">Address Line 2 (Optional)</label>
              <input
                type="text"
                class="form-control"
                placeholder="Apartment, suite, etc. (optional)"
                formControlName="apartment_suite"
              />
            </div>
            <div class="col-md-4 mb-3">
              <label for="city">City</label>
              <input
                type="text"
                class="form-control"
                placeholder="City"
                formControlName="city"
              />
              <div *ngIf="form.get('city').invalid && submit" class="text-danger">
                <small *ngIf="form.get('city').hasError('required')">City is required.</small>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="state">State</label>
              <input
                type="text"
                class="form-control"
                placeholder="State"
                formControlName="state"
              />
              <div *ngIf="form.get('state').invalid && submit" class="text-danger">
                <small *ngIf="form.get('state').hasError('required')">State is required.</small>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="zip_code">ZIP Code</label>
              <input
                type="text"
                class="form-control"
                placeholder="ZIP code"
                formControlName="zip_code"
              />
              <div *ngIf="form.get('zip_code').invalid && submit" class="text-danger">
                <small *ngIf="form.get('zip_code').hasError('required')">ZIP code is required.</small>
                <small *ngIf="form.get('zip_code').hasError('pattern')">Invalid ZIP code.</small>
              </div>
            </div>
          </div>
        
          <!-- <div class="form-check">
            <input type="checkbox" class="form-check-input" id="saveInformation" formControlName="save_information" />
            <label class="form-check-label" for="saveInformation">Save this information for next time</label>
          </div> -->
        
          <button type="submit" class="btn btn-primary checkout btn-lg w-100 mt-4" [disabled]="isLoading">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!isLoading">CONFIRM ORDER</span>
          </button>
        </form>
      </div>
    </div>

    <!-- Sección Derecha: Resumen de Pedido -->
    <div class="col-lg-5 p-3 d-none d-md-block">
      <div class="p-4 bg-white">
        <app-order-summary
          [cartItems]="cartItems"
          [totalPrice]="totalPrice"
        ></app-order-summary>

        <div class="border-top pt-3">
          <div class="d-flex justify-content-between mb-2">
            <span>Subtotal</span>
            <span>$&nbsp;{{ totalPrice | separadormiles }}</span>
          </div>
          <!-- <div class="d-flex justify-content-between mb-2">
            <span>Delivery</span>
            <span>Sin costo</span>
          </div> -->
          <div class="d-flex justify-content-between">
            <strong>Total</strong>
            <strong>$&nbsp;{{ totalPrice | separadormiles }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
