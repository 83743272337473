import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnePageCheckoutComponent } from './components/one-page-checkout/one-page-checkout.component';
import { ProductoDetalleComponent } from './components/producto-detalle/producto-detalle.component';
import { CheckoutComponent } from './components/checkout/checkout.component';


const routes: Routes = [
  { path: "", component: OnePageCheckoutComponent, pathMatch: "full" },
  { path: "detalle/:id", component: ProductoDetalleComponent },
  { path: "checkout", component: CheckoutComponent },
  {
    path: 'seguridad',
    loadChildren: () => import('./modulos/seguridad/seguridad.module').then(m => m.SeguridadModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
