import {environment} from '../../environments/environment';

const url =  "/v4/spreadsheets/";
const url_api =  "/api/";

const Servers = {
  GOOGLE: {
    baseUrl: `${url}`
  },
  MARKET: {
    baseUrl: `${url_api}`
  }
};

export { Servers };
