import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SidenavService } from './services/sidenav/sidenav.service';
import { NavigationEnd, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { NotificacionesService } from './services/notificaciones.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { KEY_AGROBEAT_PEDIDO_PENDIENTE } from './config/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  durationInSeconds = 5;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  @ViewChild('drawer', { static: true }) public drawer: MatDrawer;

  title = 'Agrobeat';

  showFiller = false;
 
  constructor(private _snackBar: MatSnackBar, private notificationService: NotificacionesService,private viewportScroller: ViewportScroller,private router: Router,private sidenavService: SidenavService){
    this.notificationService.success.subscribe(message => {
      this._snackBar.open(message, 'Close', {
        duration: this.durationInSeconds * 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['snackBar-success']
      });
    });
    this.notificationService.error.subscribe(message => {
      this._snackBar.open(message, 'Close', {
        duration: this.durationInSeconds * 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['snackBar-error']
      });
    });
    this.notificationService.info.subscribe(message => {
      this._snackBar.open(message, 'Close', {
        duration: this.durationInSeconds * 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['snackBar-info']
      });
    });
  }

  ngOnInit() {
    this.sidenavService.setDrawer(this.drawer);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([0, 0]);

      }
    });
  }
  
  onSidenavToggle(isOpened: boolean) {
    if (isOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }

}
