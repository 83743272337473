// export const SpreadsheetDev_ID = '1vT1aqPhfPToSOyOOe_B-3WO0Qv1pfG4VVpLYMp5YTwk';
// export const SpreadsheetProd_ID = '14fnwL8Mlnco2EVvrdaowclJtyWqyr30l9sGEIvEbv_c';
export const ApiKeyDev = 'AIzaSyAZhUweWgWLCGlRynWtKmEjWrc8gnTEzzc';
export const ApiKeyProd = 'AIzaSyAZhUweWgWLCGlRynWtKmEjWrc8gnTEzzc';

//Planilla General de Tiendas
export const SpreadsheetDevTiendas_ID = '1javNPHwLGsHMCq6thwHcAxVby17CuhVb5nDYAn-ATeE';
export const Spreadsheet = '1RXIdxu8CyplSgEOB4pjCvtvmecdCuKgkZ7qGdYSp7cI';

export const KEY_AGROBEAT_USER = "user_agrobeat_market";
export const KEY_AGROBEAT_PEDIDO_PENDIENTE = "pedido_pendiente_agrobeat_market";
export const KEY_AGROBEAT_CART_ROCO = "roco_cart_agrobeat_market";
export const KEY_AGROBEAT_PRODUCTOS = "productos";
export const KEY_AGROBEAT_CART = "cartItems";