
<div class="product-item mb-3">
    <div class="product-image">
        <span class="eliminar-producto" (click)="removeFromCart(data.id)"><mat-icon svgIcon="cross"></mat-icon>  </span>
        <img src="{{data.url || 'assets/images/image-placeholder.jpg'}}" alt="Product Image">
    </div>
    <div class="product-details"  (click)="irProducto(data)" style="cursor: pointer;">
        <h3 class="product-name">{{data.nombre}}</h3>
        <small class="product-unit">{{data.presentacion}}</small>
    </div>
   
    <div class="d-flex flex-column" style="gap:8px;">
        <div class="product-price">
            $&nbsp;{{data.precio | separadormiles}}
        </div>
        <app-quantity-selector [quantity]="data.cantidad" 
        (quantityChange)="updateQuantity($event)"></app-quantity-selector>
    </div>
</div>
