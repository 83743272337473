<div class="header-page">
    <div class="container-logo" (click)="irinicio()">
        <img src="assets/logo/logo-malek.svg" height="60px" alt="">
    </div>
    <div class="container-buscador" *ngIf="this.filter"> 
        <div class="form-group has-search m-0">
            <span class="form-control-feedback"><img src="assets/images/icons/magnifying-glass.png" style="height: 14px;" alt=""></span>
            <input type="text" class="form-control input-filter rounded-lg" id="inputSearch" (keyup)="onFilter($event.target.value)" placeholder="Search for products...">
            <span class="form-control-search clean-button" *ngIf="cleanButton" (click)="onCleanSearch()" style="cursor: pointer;"> 
                <img src="assets/images/icons/clean.png" style="height: 16px;" alt="">
            </span>
        </div>
    </div>
    <div class="container-carrito">
        <span class="badge-cart" *ngIf="totalItemsCart>0">{{totalItemsCart}} </span>
        <button class="button-cuadrado" (click)="onToggleSidenav()"><mat-icon svgIcon="cart"></mat-icon></button>
    </div>
</div>
 