import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Character, Product, characterAttributesMapping, productAttributesMapping } from '../model/product.model';
import { Servers } from '../config/api';
import {ApiKeyProd,KEY_AGROBEAT_PRODUCTOS,SpreadsheetDevTiendas_ID } from '../config/constants'
@Injectable({
  providedIn: 'root'
})
export class GoogleSheetService {
  private restGenericUrl = Servers.GOOGLE.baseUrl;
  private restUrl = Servers.GOOGLE.baseUrl;
  private restTiendasUrl = Servers.GOOGLE.baseUrl+ SpreadsheetDevTiendas_ID+ "/values/";
  constructor(private http: HttpClient) {
   }

  getProducts(spreadsheetid): Observable<any> {
    return this.http.get<Product>(this.restUrl+ spreadsheetid+ "/values/" + "A1:G?key="+ApiKeyProd, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  getProductoById(id): any {
      var productos = JSON.parse(localStorage.getItem(KEY_AGROBEAT_PRODUCTOS));
      var producto = productos.find(product => product.sku === id);
      return producto;
  }


  getCategories(spreadsheetid): Observable<any> {
    return this.http.get<Product>(this.restUrl+spreadsheetid+ "/values/" + "A1:G?key="+ApiKeyProd, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
  getCabecera(spreadsheetid): Observable<any> {
    return this.http.get<Product>(this.restGenericUrl + spreadsheetid+ "/values:batchGet?ranges=A1:B6&ranges=C1:D6&ranges=E1:F6&majorDimension=COLUMNS&key="+ApiKeyProd, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
  getTiendas(): Observable<any> {
    return this.http.get<Product>(this.restTiendasUrl + "A1:B?key="+ApiKeyProd, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}