<div class="detalle-carrito p-6">
  <div class="d-flex w-100 justify-content-between align-items-center">
    <h4>My Cart <span *ngIf="cartItems.length>0">({{cartItems.length}})</span> </h4>
    <button class="button-cuadrado" (click)="closeSidenav()">  <mat-icon svgIcon="cross"></mat-icon></button>
  </div>
  <div class="lista-productos">
    <div *ngFor="let producto of cartItems">
      <app-producto [data]="producto"></app-producto>
    </div>
    <app-empty-state *ngIf="cartItems.length==0"></app-empty-state>
  </div>
  <div class="summary-section" *ngIf="cartItems.length>0">
    <!-- <div class="summary-row">
      <span class="summary-label">Taxes</span>
      <span class="summary-value">$ 0</span>
    </div> -->
    <div class="summary-row total">
      <span class="summary-label">Total</span>
      <span class="summary-value price">$&nbsp;{{totalPrice | separadormiles}}</span>
    </div>

    <button class="btn btn-primary  mt-3" (click)="checkout()">Continue to checkout</button>
  </div>
</div>
