import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
})
export class CategoriasComponent implements OnInit {
  @ViewChild('categoriasContainer', { static: true })
  categoriasContainer: ElementRef;

  selectedCategory: string | null = null;

  @Input() data: any | undefined;
  @Output() categorySelected = new EventEmitter<string>();

  constructor(private scroller: ViewportScroller) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.scrollToInitialPosition();
  }

  scrollToInitialPosition() {
    setTimeout(()=>{
      const fourthCategoryElement = document.getElementById(
        'categoriasContainer'
      );
      if (fourthCategoryElement) {
        fourthCategoryElement.scrollTo({left:95,behavior:"smooth"})
      }
    },1000)
   
  }

  onSelectCategory(categoria: string) {
    this.selectedCategory =
      this.selectedCategory === categoria ? null : categoria;
    this.categorySelected.emit(this.selectedCategory);
  }
}
