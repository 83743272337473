import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartItem, CartService } from 'src/app/services/cart.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit {
  @Input() data: any | undefined;
  
  constructor(private cartService:CartService,private router:Router,private sidenavService:SidenavService) { }

  ngOnInit(): void {
  }

  removeFromCart(id:string){
    this.cartService.removeFromCart(id);
  }

  irProducto(producto:any){
    this.router.navigate([`/detalle/${producto.sku}`], { 
      queryParams: { presentacion: producto.presentacion} 
    });
    this.sidenavService.toggle();
  }

  updateQuantity(newQuantity: number) {
    if (this.data) {
      this.cartService.updateCartItemQuantity(this.data.id, newQuantity);
    }
  }

}
