<mat-drawer-container class="example-container" >
  <mat-drawer
    #drawer
    class="example-sidenav"
    mode="over"
    position="end"
    (openedChange)="onSidenavToggle($event)"
  >
    <app-carrito></app-carrito>
  </mat-drawer>
  <div class="example-sidenav-content">
    <router-outlet> </router-outlet>
  </div>
</mat-drawer-container>
