import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProducts'
})
export class FilterProductsPipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
        return items;
    }
    var itemsFilter:any[]= [];
    itemsFilter = items.filter(item => item.categoria.indexOf(filter.categoria) !== -1);
    return itemsFilter;
}
} 
