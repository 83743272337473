import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { KEY_AGROBEAT_CART } from '../config/constants';

export interface CartItem {
  id:string;
  sku:string;
  nombre: string;
  presentacion: string;
  cantidad: number;
  precio: number;
  url:string;
}

export function transformToCartItem(
  data: any,
  presentacionSeleccionada: any,
  cantidad: any
): CartItem {
  return {
    id:null,
    sku:data.sku,
    nombre: data.nombre,
    presentacion: presentacionSeleccionada,
    cantidad: cantidad,
    precio: data.precios[presentacionSeleccionada],
    url:data.url
  };
}

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private localStorageKey = KEY_AGROBEAT_CART; 
  private cartItems: CartItem[] = this.loadCartFromLocalStorage() || [];
  private cartItemsSubject = new BehaviorSubject<CartItem[]>(this.cartItems);
  cartItems$ = this.cartItemsSubject.asObservable();

  constructor() {}

  // Cargar el carrito desde localStorage
  private loadCartFromLocalStorage(): CartItem[] {
    const storedCart = localStorage.getItem(this.localStorageKey);
    return storedCart ? JSON.parse(storedCart) : [];
  }

  // Guardar el carrito en localStorage
  private saveCartToLocalStorage(): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.cartItems));
  }

  loadCart(): CartItem[] {
    const storedCart = localStorage.getItem(this.localStorageKey);
    return storedCart ? JSON.parse(storedCart) : [];
  }

  // Agregar un producto al carrito
  addToCart(product: CartItem): void {
    product.id = uuidv4();
    this.cartItems.push(product);
    this.updateCartItems();
  }

  // Eliminar un producto del carrito
  removeFromCart(id: string): void {
    this.cartItems = this.cartItems.filter((item) => item.id !== id);
    this.updateCartItems();
  }

  // Obtener el total de items en el carrito
  getTotalItems(): number {
    return this.cartItems.reduce((total, item) => total + item.cantidad, 0);
  }

  // Obtener el total del carrito (asumiendo que tienes un precio por producto)
  getTotalPrice(): number {
    // Suponiendo que puedes tener un método para obtener el precio del producto, o que lo incluyes en la interfaz CartItem.
    // Aquí falta el atributo 'precio' para calcular el precio total, te recomendaría incluirlo si es necesario.
    return this.cartItems.reduce(
      (total, item) => total + item.precio * item.cantidad,
      0
    );
  }

  updateCartItemQuantity(id: string, quantity: number): void {
    const item = this.cartItems.find(item => item.id === id);
    if (item) {
      item.cantidad = quantity;
      if (item.cantidad <= 0) {
        this.removeFromCart(id);
      } else {
        this.updateCartItems();
      } 
    }
  }
  // Método privado para actualizar el BehaviorSubject y guardar en localStorage
  private updateCartItems(): void {
    this.cartItemsSubject.next([...this.cartItems]);
    this.saveCartToLocalStorage();
  }

  // Limpiar el carrito
  clearCart(): void {
    this.cartItems = [];
    this.updateCartItems();
  }
}
