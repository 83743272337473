import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CartItem,
  CartService,
  transformToCartItem,
} from 'src/app/services/cart.service';
import { GoogleSheetService } from 'src/app/services/google-sheet.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-producto-detalle',
  templateUrl: './producto-detalle.component.html',
  styleUrls: ['./producto-detalle.component.scss'],
})
export class ProductoDetalleComponent implements OnInit {
  id: any;
  producto: any = {};
  presentacionSeleccionada: any = null;
  totalItemsCart: any = 0;
  descripcionExpandida = false;
  isMobile: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private googleSheet: GoogleSheetService,
    private sidenavService: SidenavService,
    private cartService: CartService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });

    var queryParamPresentacion =
      this.route.snapshot.queryParamMap.get('presentacion');
    if (queryParamPresentacion != null) {
      this.presentacionSeleccionada =
        this.route.snapshot.queryParamMap.get('presentacion');
    }

    this.totalItemsCart = this.cartService.getTotalItems();
    this.cartService.cartItems$.subscribe((items) => {
      this.totalItemsCart = this.cartService.getTotalItems();
    });
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
      console.log(this.id);
      this.producto = this.googleSheet.getProductoById(this.id);

      console.log(this.producto);
    });
  }

  selectPresentacion(presentacion) {
    if (this.presentacionSeleccionada == presentacion) {
      this.presentacionSeleccionada = null;
      return;
    }
    this.presentacionSeleccionada = presentacion;
  }
  openSidenav() {
    this.sidenavService.open();
  }

  closeSidenav() {
    this.sidenavService.close();
  }

  toggleSidenav() {
    this.sidenavService.toggle();
  }

  addProductToCart(product: any): void {
    const cartItem: CartItem = transformToCartItem(
      product,
      this.presentacionSeleccionada,
      1
    );
    this.cartService.addToCart(cartItem);
    this.presentacionSeleccionada = null;

    if (!this.isMobile) {
      this.sidenavService.toggle();
    }
    this.router.navigate(['/']);
  }
}
